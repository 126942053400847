import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { graphql } from 'gatsby';
import Pagination from '../components/Pagination';
import { ImageBio } from '../components/ImageBio';
import { ImageAndText } from '../components/ImageAndText';
import { ImageContainer } from '../components/ImageContainer';
import { WorkContainer } from '../components/WorkContainer';
import { ImageModal } from '../components/ImageModal';
import {
  Exit,
  Header,
  HeadingContainer,
  ImageGallery,
} from '../components/ImageGallery';
import { Artwork } from '../components/Artwork';
import { Artworks } from '../components/Artworks';

export default function HomePage({ data, location }) {
  const [artworks] = useState(data.artworks.contentSections);
  const [currentPage, setCurrentPage] = useState(1);
  const [artworkPerPage] = useState(1);
  const [url, setUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showImageGallery, setImageGallery] = useState(false);

  useEffect(() => {
    if (location.pathname) setUrl(location.pathname);
  }, [location.pathname]);

  const indexOfLastArtwork = currentPage * artworkPerPage;
  const indexOfFirstArtwork = indexOfLastArtwork - artworkPerPage;

  const currentArtwork = artworks.slice(
    indexOfFirstArtwork,
    indexOfLastArtwork
  );

  // Change the page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    document.querySelector('body').classList.add('white');
    document.querySelector('body').classList.remove('grey');
  });

  const handleShowImageFullSize = () => {
    setIsOpen(!isOpen);
  };

  const handlesShowImageGallery = () => {
    setImageGallery(!showImageGallery);
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        ease: 'easeIn',
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <WorkContainer variants={container} initial="hidden" animate="show">
      {!showImageGallery && (
        <>
          {currentArtwork.map((artwork, index) => (
            <ImageAndText key={index} variants={item}>
              <WorkImageContainer onClick={() => handleShowImageFullSize()}>
                <GatsbyImage
                  image={getImage(artwork.imageSmall.asset)}
                  alt={artwork.altText ? artwork.altText : ''}
                />
                {isOpen && (
                  <ImageModal onClick={() => handleShowImageFullSize()}>
                    <GatsbyImage
                      image={getImage(artwork.image.asset)}
                      alt={artwork.altText ? artwork.altText : ''}
                    />
                  </ImageModal>
                )}
                <ImageBio>
                  {artwork.name && <ArtworkName>{artwork.name}</ArtworkName>}
                  <ul>
                    {artwork.location && <li>{artwork.location}</li>}
                    {artwork.dimentions && <li>{artwork.dimentions}</li>}
                    {artwork.material && <li>{artwork.material}</li>}
                  </ul>
                </ImageBio>
              </WorkImageContainer>
            </ImageAndText>
          ))}
        </>
      )}

      {showImageGallery && (
        <ImageGallery onClick={() => handlesShowImageGallery()}>
          <HeadingContainer>
            <Header>Click to view sculpture</Header>
            <Exit />
          </HeadingContainer>

          <Artworks>
            {artworks.map((artwork, index) => (
              <Artwork key={index}>
                <button onClick={() => paginate(index + 1)}>
                {artwork.image && (
                  <GatsbyImage
                  image={getImage(artwork.imageSmall.asset)}
                  alt={artwork.altText ? artwork.altText : ''}
                  onClick={() => paginate(index + 1)}
                  />
                  )}
                  </button>
              </Artwork>
            ))}
          </Artworks>
        </ImageGallery>
      )}

      {!showImageGallery && (
        <Pagination
          currentPage={currentPage}
          artworkPerPage={artworkPerPage}
          totalArtworks={artworks.length}
          paginate={paginate}
          variants={item}
          url={url}
          data={artworks}
          setImageGallery={setImageGallery}
          showImageGallery={showImageGallery}
        />
      )}
    </WorkContainer>
  );
}

export const query = graphql`
  query ArtworkQuery {
    artworks: sanityPage(id: { eq: "-fe0005f4-16cd-5780-997d-f1777b85b27e" }) {
      contentSections {
        name
        location
        dimentions
        material
        altText
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageSmall {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        _key
      }
    }
  }
`;

const WorkImageContainer = styled(ImageContainer)`
  img {
    object-fit: contain !important;

    &:hover {
      cursor: zoom-in;
    }
  }
`;

const ArtworkName = styled.h2`
  text-transform: uppercase;
  font-size: 2rem;
  font-family: interstate-mono, monospace;
  font-weight: 400;
  font-style: normal;
`;
