import styled from 'styled-components';

export const ImageModal = styled.dialog`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 150;
  transition: ease-in-out 2s;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;

  img {
    max-width: 95vw;
    max-height: 95vh;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: zoom-out;
  }

  @media (min-width: 1024px) {
    background-color: rgba(119, 118, 118, 0.8);
    display: flex;
  }
`;
