import React from 'react';
import styled from 'styled-components';
import { ArtworkStyles } from './Artwork';

export const Artworks = ({ children, className }) => (
  <ArtworksStyles className={className}>{children}</ArtworksStyles>
);

export const ArtworksStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 2rem;

  ${ArtworkStyles} {
    overflow: auto;
    &:nth-child(2n + 0) {
      grid-column: span 2;
      grid-row: span 1;
    }

    &:nth-child(1) {
      grid-column: span 2;
      grid-row: span 1;
    }
    &:nth-child(3n + 0) {
      grid-column: span 1;
      grid-row: span 1;
    }
  }

  @media (min-width: 1024px) {
    grid-template-rows: repeat(6, minmax(100px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(10px, 104px));
    grid-auto-flow: dense;

    overflow-y: scroll;
    max-height: 680px;

    ${ArtworkStyles} {
      &:nth-child(2n + 0) {
        grid-column: span 2;
        grid-row: span 1;
      }

      &:nth-child(1) {
        grid-column: span 3;
        grid-row: span 1;
      }
      &:nth-child(3n + 0) {
        grid-column: span 3;
        grid-row: span 1;
      }
    }
  }
`;
