import React from 'react';
import styled from 'styled-components';

export const Artwork = ({ children, className }) => (
  <ArtworkStyles className={className}>{children}</ArtworkStyles>
);

export const ArtworkStyles = styled.div`

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  &:hover {
    cursor: pointer;
  }
  & > button div {
    max-height: 100px;
    min-height: 100px;
  }
`;
