import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useKeypress from '../utils/useKeypress';
import { PaginationStyles } from './PaginationStyles';

export default function div({
  artworkPerPage,
  totalArtworks,
  paginate,
  currentPage,
  variants,
  url,
  setImageGallery,
  showImageGallery,
}) {
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalArtworks / artworkPerPage); i += 1) {
    pageNumber.push(i);
  }

  const [isFarmPage, setIsFarmPage] = useState(false);
  const [showPreviousPage, setShowPreviousPage] = useState(false);

  useEffect(() => {
    if (currentPage !== 1) {
      setShowPreviousPage(true);
    }
  }, [currentPage, showPreviousPage]);

  useEffect(() => {
    if (url === '/the-farm') {
      setIsFarmPage(true);
    }
  }, [url]);

  const handlePreviousPage = () => {
    paginate(currentPage - 1);
    if (currentPage === 1) {
      paginate(pageNumber.length);
    }
  };

  const handleNextPage = () => {
    if (pageNumber.length > totalArtworks) return;
    if (pageNumber.length > currentPage) {
      paginate(currentPage + 1);
    } else {
      paginate(currentPage - (pageNumber.length - 1));
    }
  };

  useKeypress('ArrowLeft', () => {
    handlePreviousPage();
  });

  useKeypress('ArrowRight', () => {
    handleNextPage();
  });

  return (
    <PaginationStyles showImageGallery={showImageGallery} variants={variants}>
      <PreviousPageLink
        showPreviousPage={showPreviousPage}
        onClick={() => handlePreviousPage()}
      >
        {isFarmPage ? 'Previous page' : 'Previous work'}
      </PreviousPageLink>

      <CurrentPageStyles
        isFarmPage={isFarmPage}
        onClick={() => {
          if (url === '/') setImageGallery(!showImageGallery);
        }}
      >{`${currentPage} / ${pageNumber.length}`}</CurrentPageStyles>

      <NextPageLink onClick={() => handleNextPage()}>
        {isFarmPage ? 'Next page' : 'Next work'}
      </NextPageLink>
    </PaginationStyles>
  );
}

const NextPageLink = styled.button`
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  background-color: transparent;
  min-height: 2.3rem;

  font-family: interstate-mono, monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 1.8rem;
  letter-spacing: 1.9px;

  color: var(--grey);
  padding-bottom: ${({ isFarmPage }) => (isFarmPage ? '0' : '6px')};
  transition: all 200ms cubic-bezier(0.1, 0.1, 0.6, 0.85);
  text-decoration: none;
  border-bottom: ${({ isFarmPage }) =>
    isFarmPage ? '0' : '2px solid var(--grey)'};

  position: absolute;
  top: -6.5rem;
  right: 0;

  &:hover {
    cursor: pointer;
    margin-bottom: ${({ isFarmPage }) => (isFarmPage ? '0' : '4px')};
    padding-bottom: ${({ isFarmPage }) => (isFarmPage ? '0' : '2px')};
    border-bottom: ${({ isFarmPage }) =>
      isFarmPage ? '2px solid transparent' : '2px solid var(--grey)'};
  }

  @media (min-width: 1024px) {
    border-bottom: 2px solid transparent;
    font-size: 2rem;
    position: relative;
    top: ${({ isFarmPage }) => (isFarmPage ? 'unset' : '0')};
  }
`;

const CurrentPageStyles = styled(NextPageLink)`
  position: relative;
  top: 0;
  right: unset;
  border-bottom: ${({ isFarmPage }) =>
    isFarmPage ? 'none' : '2px solid var(--grey)'};

  &:hover {
    cursor: ${({ isFarmPage }) => (isFarmPage ? 'unset' : 'pointer')};
  }

  @media (min-width: 1024px) {
    border-bottom: 2px solid transparent;
  }
`;

const PreviousPageLink = styled(NextPageLink)`
  opacity: ${({ showPreviousPage }) => (showPreviousPage ? '1' : '0')};
  left: 0;
  max-width: 170px;

  @media (min-width: 1024px) {
    max-width: 100%;
  }
`;
